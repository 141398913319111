export default {
  data: () => {
    return {
      systemInit: false, //系统是否已初始化
      default_active: "", //当前高亮的菜单Route
      nativeTabId: "", //当前显示的TabID
      openTableList: [], //已打开的页面集合
      scrollX: 0, //导航栏基准位置
      thisTabId: null, //当前操作的菜单ID
      is_fold: false, //菜单是否折叠
      is_fold_right: false, //右边是否折叠 将右边盒子折叠与菜单折叠分开，这样可以减少动画的卡顿现象
      is_full_screen: false, //是否全屏
      now_time: "加载中...", //当前时间
      themeToggling: false, //主题是否正在切换
      themeList: [
        { name: "白色", value: "1", show_all: false },
        { name: "暗色", value: "2", show_all: false },
      ], //可选主题列表
      avatar: "", //头像
      dropList: [
        {
          name: "个人中心",
          click(_vm) {
            _vm.$router.push({ name: "UserInfoCard" });
          },
        },
        {
          name: "退出登录",
          click() {
            window.localStorage.clear();
            window.sessionStorage.clear();
            window.location.reload();
          },
        },
      ], //头像处下拉列表菜单
    };
  },
  watch: {
    /**
     * 监听全屏动作
     * @param {string} newValue
     */
    is_full_screen(newValue) {
      if (newValue) {
        this.$utils.fullScreen(); //调用方法进行全屏动作
      } else {
        this.$utils.fullScreenNormal(); //调用方法进行退出全屏动作
      }
    },

    /**
     * 切换页面时 同步更换title
     * @param {string} newValue
     */
    nativeTabId(newValue) {
      var title = this.$state.title_prefix;
      let preX = this.$utils.arrayGetIndex(this.openTableList, "id", newValue);
      if (preX !== null) {
        title += this.openTableList[preX]["name"];
      }
      document.querySelector("title").innerHTML = title;
    },

    //监听路由变动
    $route(to) {
      //切换到导航
      this.activeNav(to.meta.id);
    },
  },
  computed: {},
  methods: {
    /**
     * 初始化模板
     */
    init() {
      if (this.systemInit) {
        return;
      }
      //打印版本等信息
      console.log("欢迎使用kc-admin-vue，version：" + this.$state.version);
      this.initWindow(); //初始化window
      this.to_greet(); //初始化问候语
      this.systemInit = true; //写入系统初始化状态
    },

    /**
     * 初始化window相关配置
     */
    initWindow() {
      //打开首页
      if (!this.$state.openHome) {
        this.$router.push({ name: "Home" });
        this.$store.commit("SET_OPEN_HOME", true);
        setTimeout(() => {
          this.activeNav(this.$route.meta.id); //手动触发路由变动
        }, 500);
      }

      //手动触发一下窗口变动
      this.onresize();

      //载入主题
      var themeV = localStorage.getItem("themeV");
      if (themeV) {
        this.$store.commit("SET_THEME_V", themeV);
      }

      //设置头像
      this.avatar =
        process.env.VUE_APP_BASE_URL +
        "/resources/" +
        this.$store.state.user.avatar;

      //设置水印
      this.$utils.setWatermark("飞鱼国际物流", this.$state.user.username);

      //更新主页上的时间显示
      if (window.updatetimes) {
        //如果定时器已存在就先销毁定时器
        clearInterval(window.updatetimes);
      }
      window.updatetimes = setInterval(this.nowTime, 1000);
    },

    /******************** 小工具/控件 ********************/

    /**
     * 切换主题
     * @param {string} command 主题ID
     */
    switchTheme(command) {
      //设置主题切换装填为开启,关闭动画效果避免卡顿
      this.themeToggling = true;
      //开始切换
      this.$store.commit("SET_THEME_V", command);
      localStorage.setItem("themeV", command);
      this.$nextTick(() => {
        //重新打开动画效果
        this.themeToggling = false;
      });
    },

    /**
     * 折叠左侧导航菜单
     */
    foldLeftMenu() {
      this.is_fold_right = true;
      this.is_fold = true;
    },

    /**
     * 展开左侧导航菜单
     */
    openLeftMenu() {
      this.is_fold = false;
      this.is_fold_right = false;
    },

    /**
     * 重载框架
     */
    reloadFrame() {
      this.$router.go(0);
    },

    /**
     * 重载页面
     */
    reloadPage() {
      //未实现
    },

    /******************** 导航栏 ********************/

    /**
     * 路由变动时回调 激活导航栏
     * @param {string} id 菜单ID
     * 例: ManageMenu Home 等
     */
    activeNav(id) {
      //获取菜单
      var menu = this.getMenuById(this.$state.menuList, id);
      if (menu !== null) {
        this.switchTab(menu);
      }
    },

    /**
     * 却换到指定导航 如果不存在则先添加
     * @param {*} tab
     * @returns
     */
    switchTab(tab) {
      //如果是当前正在显示的tab 则直接返回
      if (tab.id == this.nativeTabId) {
        return;
      }

      //如果没有先添加
      if (this.getOpenTabById(tab.id) === null) {
        this.$store.commit("UP_OPEN_TAB", tab.route); //页面名称写入缓存
        //添加Tab
        this.openTableList.push(tab);
      }

      //显示
      this.$nextTick(function () {
        //切换到指定页面
        this.$router.push({ name: tab.route });
      });

      //改写当前显示的页面ID
      this.nativeTabId = tab.id;

      //与左侧菜单关联 修改当前高亮
      this.default_active = tab.route;

      //归位一下
      this.$nextTick(() => {
        this.scrollToAuto();
      });
    },

    /**
     * 根据ID获取已打开的Tab
     * @param {string} id
     * @returns
     */
    getOpenTabById(id) {
      for (var i = 0; i < this.openTableList.length; i++) {
        if (this.openTableList[i].id === id) {
          return this.openTableList[i];
        }
      }
      return null;
    },

    /**
     * 导航自动归位
     * @returns
     */
    scrollToAuto() {
      try {
        var width = document.querySelector(".nav-right-2").clientWidth; //视角宽度
        var left = document.querySelector(".tab-native").lastChild.offsetLeft; //当前native-tilte下一个距离左边的距离
        //如果在视图右边越界
        if (left + this.scrollX > width - 100) {
          return this.scrollToRight();
        }
        //如果在视图左边越界
        if (left + this.scrollX < 0) {
          return this.scrollToLeft();
        }
      } catch (e) {
        //throw e;
      }
    },

    /**
     * 获取指定index的菜单
     * @param {object} menuList 菜单组
     * @param {string} id 菜单ID
     * @returns
     */
    getMenuById(menuList, id) {
      for (var i = 0; i < menuList.length; i++) {
        var menu = menuList[i];
        if (menu.id + "" === id + "") {
          return menu;
        }
        //处理多级菜单
        if (menu.childList) {
          var menu2 = this.getMenuById(menu.childList, id);
          if (menu2 !== null) {
            return menu2;
          }
        }
      }
      return null;
    },

    /**
     * 关闭页面
     * @param {*} tab
     * @param {*} callFn 回调
     */
    closeTab(tab) {
      //执行关闭动画
      var div = document.querySelector("#tab-" + tab.id);
      div.style.width = div.offsetWidth + "px";
      setTimeout(function () {
        div.style.width = "0px";
      }, 0);

      //等待动画结束
      setTimeout(() => {
        //如果tab为当前正在显示的tab, 则先切换到它前一个或后一个页面
        if (tab.id == this.nativeTabId) {
          var index = this.openTableList.indexOf(tab);
          var preTab = this.openTableList[index - 1];
          //切换页面 如果都不存在就跳转到首页
          if (preTab) {
            this.switchTab(preTab);
          } else {
            preTab = this.openTableList[index + 1];
            if (preTab) {
              this.switchTab(preTab);
            } else {
              this.$router.push({ name: "Home" });
            }
          }
        }
        //开始从集合中移除
        this.$utils.arrayDelete(this.openTableList, tab);
        let vm = this;
        this.$store.commit("CLOSE_OPEN_TAB", { route: tab.route, _vm: vm });
      }, 130);
    },

    /**
     * 导航栏左滑
     */
    scrollToLeft() {
      var width = document.querySelector(".nav-right-2").clientWidth; //视角宽度
      this.scrollX += width / 2; //向左滑动
      //越界检查
      setTimeout(() => {
        if (this.scrollX > 0) {
          this.scrollX = 0;
        }
      }, 200);
    },

    /**
     * 导航栏右滑
     */
    scrollToRight() {
      var width = document.querySelector(".nav-right-2").clientWidth; //视角宽度
      var tabListWidth = document.querySelector(".tab-title-box").clientWidth; //title总盒子宽度
      var rightLimit = 0 - tabListWidth + width / 2; //右滑的极限
      this.scrollX -= width / 2; //向右滑动
      //越界检查
      setTimeout(() => {
        if (this.scrollX < rightLimit) {
          this.scrollX = rightLimit;
        }
        //同时防止左边越界
        if (this.scrollX > 0) {
          this.scrollX = 0;
        }
      }, 200);
    },

    //处理userinfo的下拉点击
    handleCommand(command) {
      var _vm = this;
      this.dropList.forEach(function (drop) {
        if (drop.name == command) {
          drop.click(_vm);
        }
      });
    },

    /******************** 小功能 ********************/

    /**
     * 窗口大小变动触发
     */
    onresize() {
      if (document.body.clientWidth < 1200) {
        this.foldLeftMenu(); //折叠左侧导航菜单
      } else {
        this.openLeftMenu(); //展开左侧导航菜单
      }
    },

    nowTime() {
      var da = new Date();
      var Y = da.getFullYear(); //年
      var M = da.getMonth() + 1; //月
      var D = da.getDate(); //日
      var h = da.getHours(); //小时
      var sx = "凌晨";
      if (h >= 6) {
        sx = "上午";
      }
      if (h >= 12) {
        sx = "下午";
        if (h >= 18) {
          sx = "晚上";
        }
        h -= 12;
      }
      var m = da.getMinutes() < 10 ? "0" + da.getMinutes() : da.getMinutes(); //分
      var s = da.getSeconds(); //秒
      var z = ["日", "一", "二", "三", "四", "五", "六"][da.getDay()]; //周几
      //z = z == 0 ? '日' : z;
      var nowtime = "";

      nowtime +=
        Y +
        "-" +
        M +
        "-" +
        D +
        " " +
        sx +
        " " +
        h +
        ":" +
        m +
        ":" +
        s +
        " 周" +
        z;
      this.now_time = nowtime;
    },
    to_greet() {
      var da = new Date();
      var h = da.getHours(); //小时
      var greet;
      var bless;
      if (h >= 0 && h <= 6) {
        //凌晨
        greet = "夜深了";
        bless = "早点休息吧!(¦3[▓▓]";
      } else if (h > 6 && h <= 9) {
        //早上
        greet = "早上好";
        bless = "美好的一天又开始了!ヾ(ｏ･ω･)ﾉ";
      } else if (h > 9 && h <= 11) {
        //上午
        greet = "上午好";
        bless = "休息一会儿吧!ヾ(o′▽`o)ノ°°";
      } else if (h > 11 && h <= 13) {
        //中午
        greet = "中午好";
        bless = "准备吃什么呢？(*￣ω￣)";
      } else if (h > 13 && h <= 18) {
        //下午
        greet = "下午好";
        bless = "要不要打一把 DOTA(  ｰ̀ωｰ́ )⊃⌒";
      } else if (h > 18 && h <= 19) {
        //傍晚
        greet = "傍晚好";
        bless = "是时候该下班了(#^.^#)!";
      } else if (h > 19 && h <= 23) {
        //夜晚
        greet = "晚上好";
        bless = "我猜你可能累了!(っ ̯ -｡)";
      }

      this.$store.state.greet = greet;
      this.$store.state.bless = bless;
    },
  },
  mounted() {
    this.init(); //初始化模板
  },
};
